<template>
    <div class="home">
        <div class="head">
            <div>
                <img src="../../assets/image/aa_pic_logo.png" />
            </div>
            <div class="h-right">
                <img src="../../assets/image/aa_icon_shangchengshouye.png" />
                <div @click="toHome" style="margin-left:6px;cursor:pointer">商城首页</div>
            </div>
        </div>
        <div class="l-con">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        const toHome=()=>{
            router.push('/')
        }
        return{
            toHome
        }
    }
}
</script>
<style scoped>
    .home{
        width: 100%;
        height: 750px;
        display: flex;
        flex-direction: column;
    }
    .head{
        width: 1200px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .h-right{
        display: flex;
        align-items: center;
    }
    .l-con{
        flex: 1;
        background: rgb(241, 244, 250);
    }
</style>